body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.grid-image .ant-image {
  height: 100% !important;
  width: 100% !important;
}
.ant-collapse-content-box {
  padding-top: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .liked-icon {
    animation: like-logo 0.7s ease-in-out;
  }
}

@keyframes like-logo {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.8);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.4);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}
.ql-container {
  height: 300px !important;
}

.html-render p {
  margin: 0 !important;
}
